
<template>
   <div class="card text-center py-2 cursor-pointer hover d-flex" :class="pesquisa.subcategoria == subcategoria ? 'bg-selected' : ''" @click="pesquisar">
      <p class="limitador font-12 mb-0 align-self-center">{{ subcategoria }}</p>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Subcategoria',
   props: ['subcategoria', 'categoria'],
   computed: {
		... mapState({
			pesquisa: state => state.pesquisa
		})
	},
   methods: {
      pesquisar : function () {
         this.$store.dispatch('pesquisar', {'valor': '', 'categoria': this.categoria, 'subcategoria': this.subcategoria})
      }
   }
}

</script>