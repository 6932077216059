
<template>
   <div class="product__item white-bg mx-auto mx-sm-0">
      <div class="product__thumb p-relative" @click="verProduto">
         <a href="javascript:;" class="w-img">
            <img class="bg-white cursor-pointer" :src="produto.fotos.length == 0 ? '' : produto.fotos[0].foto" alt="product" @error="imageError" />
         </a>
         <div class="product__offer" v-if="page == 'OFERTAS'">
            <span class="discount"><i class="far fa-percentage"></i></span>
         </div>
      </div>
      <div class="product__content pt-15 text-center">
         <h6 class="product-name mb-2">
            <a class="product-item-link" href="javascript:;" :title="produto.nome" @click="verProduto">{{ produto.nome }}</a>
         </h6>
         <span class="price" v-if="produto.estoque >= 1">
            R$ {{ parseFloat(produto.preco).toFixed(2) }}
         </span>
         <span class="price font-14" v-else>
            <i class="far fa-box text-danger font-12 me-1"></i> Sem estoque
         </span>
      </div>
      <div class="product__add-btn" v-if="produto.estoque >= 1">
         <div class="col-7 px-1">
            <div class="cart-plus-minus p-relative">
               <input type="text" v-model="produto.quantidade" disabled class="form-control" v-mask="'###############'" />
               <div class="dec qtybutton" title="Remover" @click="alterarQuantidade('MINUS')" v-if="parseFloat(produto.quantidade) > 0.25">
                  <i class="fas fa-minus"></i>
               </div>
               <div class="inc qtybutton" title="Adicionar" @click="alterarQuantidade('PLUS')">
                  <i class="fas fa-plus"></i>
               </div>
            </div>
         </div>

         <div class="col-5 px-1">
            <button type="button" @click="addCarrinho" title="Adicionar ao carrinho"><i class="far fa-shopping-cart"></i></button>
         </div>
      </div>
   </div>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'

export default {
	name: 'Produto',
   props: ['produto', 'page'],
   data: function() {
		return {
         urlAtual: window.location.hostname
		}
	},
   computed: {
		... mapState({
			pesquisa: state => state.pesquisa
		})
	},
   methods: {
      alterarQuantidade : function (tipo) {
         if (tipo == 'MINUS') {
            this.produto.quantidade = String(parseInt(this.produto.quantidade) - 1)

         } else {
            this.produto.quantidade = String(parseInt(this.produto.quantidade) + 1)
         }
      },
      addCarrinho: function () {
         this.$store.dispatch('addCarrinho', this.produto)

         this.$toast.fire({
            icon: 'success',
            title: 'Adicionado ao carrinho!'
         });

         this.$store.dispatch('configurarCarrinho')
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      verProduto : function () {
         router.push({ name: 'Produto', params: { produto: JSON.stringify(this.produto) } })
      }
   },
   created() {
      this.produto.quantidade = String(1)
   }
}

</script>

<style scoped>

.product__item {
   max-width: 300px;
   width: 100%;
}

</style>